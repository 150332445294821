import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A poster entitled `}<strong parentName="p">{`African geology, the Bouvet mantle plume and the early opening of the Gondwana margins`}</strong>{` was presented at the 2020 Netherlands Earth Science congress in Utrecht, March 12.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.reeves.nl/upload/Reeves-NACgeo2020-2pages.pdf"
        }}>{`Click here to see the poster`}</a></li>
    </ul>
    <p><em parentName="p">{`Last updated: 2021 May 3`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "../other-material"
          }}>{`Return to Global Thoughts page`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "../../gondwana"
          }}>{`Return to Gondwana man page`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      